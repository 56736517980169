import { MvCountryTag } from '@/lib/strapi-types/MvCountryTag';
import { DevBackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { Strapi4ResponseMany } from '@nuxtjs/strapi/dist/runtime/types';
import { stringify } from 'qs';

interface UseFindCountryTagsProps {
  filters: Record<string, unknown>;
  populate?: string[];
}

export async function useFindCountryTags({ filters = {}, populate = [] }: UseFindCountryTagsProps) {
  const defaultPopulate = [''];
  const allPopulate = [...defaultPopulate, ...populate];

  const parsedQuery = stringify(
    {
      populate: allPopulate,
      filters
    },
    { arrayFormat: 'brackets' }
  );

  const { data } = await useFetchWithHead<Strapi4ResponseMany<MvCountryTag['attributes']>>(
    `${useCompleteUrl(DevBackEndpoints.COUNTRY_TAGS)}?${parsedQuery}`
  );

  return { countryTags: data.value?.data };
}
