<template>
  <div id="page">
    <SectionHero
      v-if="hero"
      :images="[{ title: hero.title ?? '', url: hero.image?.data.attributes.url ?? '' }]"
      :title="hero.title"
      :cta="hero.CTA"
      :input-placeholder="hero.inputPlaceholder"
      is-large-header
    />

    <SectionCurrentDestinations
      v-if="countryTags && countryTags.length > 0"
      title="Parcourez nos destinations du moment"
      :tags="countryTags"
    />

    <LazySectionInspiration
      v-if="show"
      title="Découvrez nos thématiques"
      homepage
      :top-themes="primaryThemes"
      :bottom-themes="secondaryThemes"
    />

    <LazySectionJourneysBlock
      v-if="show && continents"
      :continents="continents"
      :themes="primaryThemes"
      :marketing-push="marketingPush"
      homepage
    />

    <LazySectionExperts class="experts" :style="{ background: '#FFFFFF' }" />

    <LazySectionMiniInfos
      v-if="show && imageAndText1Section"
      :image="imageAndText1Section.image?.data.attributes.url"
      :icon="imageAndText1Section.icon?.data?.attributes?.url"
      :subtitle="imageAndText1Section.subtitle"
      :description="imageAndText1Section.description"
      :title="imageAndText1Section.title"
      :cta="imageAndText1Section.CTA"
    />
    <SectionRatings />
    <LazySectionServices
      v-if="show && imageAndText2Section"
      class="services-desktop"
      :image="imageAndText2Section.image?.data.attributes.url"
      :icon="imageAndText2Section.icon?.data?.attributes?.url"
      :subtitle="imageAndText2Section.subtitle"
      :description="imageAndText2Section.description"
      :title="imageAndText2Section.title"
      :cta="imageAndText2Section.CTA"
    />

    <LazySectionMiniInfos
      v-if="show && imageAndText2Section"
      class="services-mobile"
      :image="imageAndText2Section.image?.data.attributes.url"
      :icon="imageAndText2Section.icon?.data?.attributes?.url"
      :subtitle="imageAndText2Section.subtitle"
      :description="imageAndText2Section.description"
      :title="imageAndText2Section.title"
      :cta="imageAndText2Section.CTA"
    />

    <LazySectionResponsible
      v-if="show && common?.responsibleSection"
      :title="common.responsibleSection.title"
      :description="common.responsibleSection.description"
      :cta="common.responsibleSection.CTA"
      :image="common.responsibleSection.image"
    />

    <StickyBar v-if="show" />
  </div>
</template>
<script lang="ts" setup>
const rootStore = useRootStore();
const { common } = storeToRefs(rootStore);
const filtersStore = useFiltersStore();
const { pushToDataLayer } = useDataLayer();

const show = ref(false);

onMounted(() => {
  filtersStore.clearJourneysFilters();
});

const [
  { imageAndText1Section, imageAndText2Section, hero, marketingPush },
  { countryTags },
  { primaryThemes, secondaryThemes },
  { continents }
] = await Promise.all([
  useHomePage(),
  useFindCountryTags({
    filters: { featured: true },
    populate: ['countries.thumbnail', 'countries.continents']
  }),
  useFindThemes(),
  useFindContinents()
]);

useSeoMeta({
  title: `Spécialiste du voyage sur-mesure ▷ Marco Vasco`,
  ogTitle: `Spécialiste du voyage sur-mesure ▷ Marco Vasco`,
  description: `Spécialiste du voyage sur mesure en Afrique, Amérique, Asie, et Pacifique, MARCO VASCO adapte votre voyage à votre rythme, selon vos envies.`,
  ogDescription: `Spécialiste du voyage sur mesure en Afrique, Amérique, Asie, et Pacifique, MARCO VASCO adapte votre voyage à votre rythme, selon vos envies.`
});
const baseURL = useRuntimeConfig().public.baseURL;
onMounted(() => {
  pushToDataLayer({
    BU_pageCategory: 'category',
    mid: 0,
    nodeType: 'homepage_3w',
    desti_id: '1',
    desti_name: 'marcovasco',
    pageType: 'category',
    page_attr_tagname1: `spécialiste du voyage sur-mesure`,
    page_attr_author: 'Marco Vasco',
    page_attr_userstatus: 'incognito',
    page_attr_userstate: 'not-connected'
  });
});

useHead(() => ({
  link: [
    {
      rel: 'canonical',
      href: `${baseURL}`
    }
  ],
  meta: [
    {
      name: 'ahrefs-site-verification',
      content: `78df12dc00a04c44e32d4608b51ecb7f6d3b350a375c97e092b8dcd1a6f13938`
    }
  ]
}));

setTimeout(() => {
  show.value = true;
}, 800);

useJsonld({
  '@context': 'https://schema.org',
  '@type': 'Review',
  name: 'Marco Vasco',
  aggregateRating: {
    '@type': 'AggregateRating',
    author: 'Marco Vasco',
    ratingValue: 0, // TO_BE_DEFINED
    reviewCount: 0 // TO_BE_DEFINED
  }
});
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/colors.scss';
@use '$/z-index.scss';
@use '$/metrics.scss';

#page {
  flex-direction: column;

  .services-desktop {
    @include breakpoints.mobile() {
      display: none;
    }
  }

  .services-mobile {
    display: none;

    @include breakpoints.mobile() {
      display: flex;
    }
  }
}
</style>
